import React from 'react'
import { Div, H1 } from 'glamorous'

import Layout from '../components/layout'
import CenteredContent from '../components/centered-content'
import Jumbotron from '../components/jumbotron'

import { lightBlue, darkBlue } from '../shared/colors'
import { smallerThan, largerThan } from '../shared/media-queries'

const PrivacyPage = () => {
	return (
		<Layout title="Privacy Statement">
			<Jumbotron
				excludeTop
				tiltRightColor={lightBlue}
				tiltLeftColor={darkBlue}
				tiltOnTop="tiltLeft"
			>
				<CenteredContent>
					<Div
						color="#fff"
						display="flex"
						flexDirection="column"
						alignItems="center"
						css={{
							[smallerThan('x-small')]: {
								paddingTop: '50px',
								paddingBottom: '30px',
							},
							[largerThan('x-small')]: {
								paddingTop: '90px',
								paddingBottom: '70px',
							},
						}}
					>
						<H1
							margin={0}
							css={{
								[smallerThan('x-small')]: {
									fontSize: '36px',
								},
								[largerThan('x-small')]: {
									fontSize: '56px',
								},
							}}
						>
							Privacy Statement
						</H1>
					</Div>
				</CenteredContent>
			</Jumbotron>

			<Div
				css={{
					[smallerThan('x-small')]: {
						height: '10px',
					},
					[largerThan('x-small')]: {
						height: '20px',
					},
				}}
			/>

			<CenteredContent maxWidth={1068}>
				<p>
					BuildingConnected, Inc. (“BuildingConnected”) was acquired by Autodesk
					on January 23, 2019. Please be advised that effective May 28, 2020,
					Autodesk’s{' '}
					<a
						href="https://www.autodesk.com/company/legal-notices-trademarks/privacy-statement/"
						target="_blank"
					>
						Privacy Statement
					</a>{' '}
					applies to BuildingConnected Offerings and sets forth how Autodesk may
					collect, use, and disclose personal data of or relating to you.
				</p>
				<p>
					As stated in the Autodesk Privacy Statement, certain jurisdictions
					grant individuals rights with respect to their personal data. To
					exercise these rights with respect to BuildingConnected Offerings,
					please contact{' '}
					<a href="https://buildingconnected.zendesk.com/hc/en-us?solvvy=true" target="_blank">
						support
					</a>
					.
				</p>
			</CenteredContent>
		</Layout>
	)
}

export default PrivacyPage
